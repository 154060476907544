
import { Component, Mixins } from 'vue-property-decorator';
import RatesColorPicker from '@/modules/rates/components/calendar/rates-color-picker.vue';
import RatesCalendar from '@/modules/rates/components/calendar/rates-calendar.vue';
import DateDocumentFilter from '@/modules/document-filters/components/date-document-filter.vue';
import RatesActions from '@/modules/rates/components/actions/rates-actions.vue';
import PageWrapper from '@/modules/common/components/page-wrapper.vue';
import RatesHeader from '@/modules/rates/components/rates-header.vue';
import TogglePrices from '@/modules/document-filters/components/toggle-prices.vue';
import RatesPageOtelLogs from '@/modules/open-telemetry/mixins/rates/rates-page-otel-logs.mixin';
import RatesOutOfRangeMessage from '../components/rates-out-of-range-message.vue';

@Component({
    components: {
        DateDocumentFilter,
        RatesColorPicker,
        RatesCalendar,
        RatesActions,
        PageWrapper,
        RatesHeader,
        TogglePrices,
        RatesOutOfRangeMessage,
    },
})
export default class RatesCalendarPage extends Mixins(RatesPageOtelLogs) {
    get disableBtn() {
        return this.ratesFiltersService.isProviderAll || this.ratesFiltersService.storeState.isPriceShownSwitchDisabled;
    }

    get isOutOfRange() {
        return this.ratesService.isOutOfRange() && !this.ratesService.isLoading;
    }
}
