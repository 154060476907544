
import { Component, Vue, Prop } from 'vue-property-decorator';
import { inject } from '@/inversify';
import type Day from '@/modules/common/types/day.type';
import RatesFiltersService, { RatesFiltersServiceS } from '@/modules/rates/rates-filters.service';
import RatesService, { RatesServiceS } from '@/modules/rates/rates.service';
import RatesAllService, { RatesAllServiceS } from '@/modules/rates/rates-all.service';
import BaseCard from './base-card.vue';

@Component({ components: { BaseCard } })
export default class NoData extends Vue {
    @inject(RatesServiceS) private ratesService!: RatesService;
    @inject(RatesFiltersServiceS) private ratesFiltersService!: RatesFiltersService;
    @inject(RatesAllServiceS) ratesAllService!: RatesAllService;

    @Prop({
        required: true,
        type: Number,
    })
    private day!: Day;

    get filterAll() {
        return this.ratesFiltersService.isProviderAll;
    }

    get isOutOfRange() {
        if (this.filterAll) {
            return this.ratesAllService.isOutOfRange();
        }
        return this.ratesService.isOutOfRange();
    }
}
