
import { Component, Vue } from 'vue-property-decorator';
import CalendarItemWrapper from '@/modules/common/components/ui-kit/calendar-item-wrapper.vue';

@Component({
    components: {
        CalendarItemWrapper,
    },
})
export default class CalendarItemEmpty extends Vue {}
